
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, ref, getCurrentInstance } from 'vue';
import { ManageLogin, manageMenu } from '../api/user';
import storage from 'store';
import { useInitRouter } from '@/util/router';
// import IM from "@/util/IM";

export default defineComponent({
  setup() {
    const { proxy }: any = getCurrentInstance();
    const { routerPush } = useInitRouter();
    const formRef = ref();
    const formState = reactive({
      Account: '',
      Password: '',
    });
    const rules = {
      Account: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
      Password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
    };

    const goLogin = () => {
      ManageLogin(formState).then((result) => {
        storage.set('ACCESS_TOKEN', result.data);

        proxy.$message.success('登录成功');
        manageMenu().then((result) => {
          storage.set('menu', result.data);
          routerPush('/admin');
        });
        // IM.IMlogin('admin89867828172621')
        // routerPush('/admin');
      });
    };

    return {
      formRef,
      formState,
      goLogin,
      rules,
      labelCol: {
        span: 0,
      },
      wrapperCol: {
        span: 24,
      },
    };
  },
  // methods: {
  //   goLogin() {
  //     console.log(this.formState.value)
  //   }
  // },

  components: {
    UserOutlined,
    LockOutlined,
  },
});
